import io from "socket.io-client";
import { API_URL } from "./config";

const ws = io("https://case.roketka.ru", {
  // https://localhost:3003
  transports: ["websocket"],
  withCredentials: true,
  reconnection: false,
});

export default ws;
