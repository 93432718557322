import React from 'react';
import Fade from 'react-reveal/Fade';
import { Tooltip } from 'antd';
import map from 'lodash/map';
import { connect } from 'react-redux';
import OpenCase from '../components/modules/OpenCase';
import { getCaseById } from '../api/all/cases';
import { renderItemProp } from '../helpers/Case';
import Loader from '../components/mini/Loader';
import { itemInfoFetch } from '../store/actions/itemCache';
import ItemColor from '../components/mini/ItemColor';

const mapStateToProps = (state) => ({
  itemCache: state.itemCache,
});

const mapDispatchToProps = (dispatch) => ({
  itemInfoFetch: (id) => dispatch(itemInfoFetch(id)),
});

const isHaveColors = (colors) => {
  if (!colors || !Array.isArray(colors)) {
    return false;
  }

  if (colors.length === 1) {
     return true;
  }

  return false;
};

class Case extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      caseData: [],
      caseCollection: [],
      fetching: false,
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  // eslint-disable-next-line react/sort-comp
  getShortInfoItem(item, fieldName = null) {
    const { id } = item;
    const { itemCache } = this.props;

    if (fieldName === null) {
      return itemCache[id];
    }

    if (itemCache[id]) {
      return itemCache[id][fieldName];
    }

    return '';
  }

  addItemsToCache(arrayItemIds) {
    const { itemCache } = this.props;
    for (let index = 0; index < arrayItemIds.length; index++) {
      const id = arrayItemIds[index];
      if (!itemCache[id]) {
        this.props.itemInfoFetch(id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          id: this.props.match.params.id,
        },
        () => this.getData(),
      );
    }
  }

  async getData() {
    this.setState({ fetching: true });
    const { id } = this.state;
    const { history } = this.props;

    const caseData = await getCaseById(id).then((data) => data);

    if (!caseData) {
      history.push('/404');
      return;
    }

    const massiveIds = [];

    caseData.caseCollection.ITEMS.forEach((element) => {
      massiveIds.push(element.id);
    });

    this.addItemsToCache(massiveIds);

    this.setState({
      caseCollection: caseData.caseCollection,
      caseData: caseData.data,
      fetching: false,
    });
  }

  render() {
    const { caseCollection, caseData, fetching } = this.state;
    return (
      <div className="casepage">
        {fetching ? (
          <Loader />
        ) : (
          <>
            <div className="casepage-openbutton">
              <OpenCase data={caseData} />
            </div>

            <span className="casepage-title-second">
              Содержимое кейса
{' '}
              <i>
                Количество открытий:
                {caseData.case_openedCount}
              </i>
            </span>
            <div className="casepage-itemlist more">
              {map(caseCollection.ITEMS, (item, i) => (
                <Fade delay={i * 50} key={`itemlist${item.id}`}>
                  <Tooltip
                    placement="bottom"
                    title={renderItemProp(this.getShortInfoItem(item))}
                  >
                    <div
                      className={`casepage-itemlist_item rc-${this.getShortInfoItem(
                        item,
                        'item_rare',
                      )}`}
                      style={{
                        backgroundImage: `url(/img/items/${item.id}.webp)`,
                      }}
                    >
                      {isHaveColors(item.colors) && (
                        <ItemColor color={item.colors[0]} />
                      )}
                      <span>{this.getShortInfoItem(item, 'item_name')}</span>
                    </div>
                  </Tooltip>
                </Fade>
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Case);
