import React from 'react';
import {
 Form, Input, Button, Tooltip, Card,
} from 'antd';
import { connect } from 'react-redux';
import { usePromocodeFetch } from '../../store/actions/user';
import openNotification from '../mini/Notification';

const mapDispatchToProps = (dispatch) => ({
  usePromocodeFetch: (body) => dispatch(usePromocodeFetch(body)),
});

const Promocode = (props) => {
  const onFinish = (values) => {
    // eslint-disable-next-line promise/catch-or-return
    props
      .usePromocodeFetch({
        promocode: values.promocode,
      })
      .then((data) => {
        // eslint-disable-next-line promise/always-return
        if (!data.balance) {
          openNotification('error', 'Ошибка', data.message);
          return;
        }
        openNotification('success', 'Промокод активирован', data.message);
      });
  };

  return (
    <Card
      type="inner"
      title="Использовать промокод"
      className="blockstyle-first"
      extra={(
        <Tooltip
          placement="top"
          title="Участвуйте в раздачах, от стримеров или в группе Roketka"
        >
          Где взять?
        </Tooltip>
      )}
    >
      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
        layout="inline"
      >
        <Form.Item
          name="promocode"
          rules={[{ required: true, message: 'Введите промокод!' }]}
        >
          <Input placeholder="Введите код" style={{ minWidth: '200px' }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="color-green">
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default connect(null, mapDispatchToProps)(Promocode);
