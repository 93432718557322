/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import { Row, Col, Input } from 'antd';
import map from 'lodash/map';
import H2A from '../components/mini/H2A';
import { getItemList } from '../api/all/item';
import { sortItemListRare } from '../helpers/Item';
import ItemPrice from '../components/mini/ItemPrice';

const { TextArea } = Input;

class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      fetching: false,
      choosedItems: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ fetching: true });

    const items = await getItemList().then(({ data }) => data);
    const sorted = sortItemListRare(items);

    this.setState({
      items: sorted,
      fetching: false,
    });
  }

  isActive(id) {
    const { choosedItems } = this.state;
    return choosedItems.includes(id);
  }

  chooseItem(id) {
    const { choosedItems } = this.state;

    if (choosedItems.includes(id)) {
      this.setState({
        choosedItems: choosedItems.filter((itemId) => id !== itemId),
      });
      return;
    }

    this.setState({
      choosedItems: [...choosedItems, id],
    });
  }

  renderChoosedItems() {
    const { items, choosedItems } = this.state;

    const itemList = [];

    for (const key in choosedItems) {
      const element = choosedItems[key];
      const findedItem = items.find((item) => item.id === element);
      itemList.push(findedItem);
    }

    return (
      <>
        {map(itemList, (item) => (
          <div
            className={
              this.isActive(item.id)
                ? `casepage-itemlist_item active rc-${item.rare}`
                : `casepage-itemlist_item rc-${item.rare}`
            }
            style={{
              backgroundImage: `url(/img/items/${item.id}.webp)`,
            }}
            onClick={() => this.chooseItem(item.id)}
          >
            <ItemPrice price={item.pricesInCredits?.default} />
            <span>{item.name}</span>
          </div>
        ))}
      </>
    );
  }

  renderItemList() {
    const { items, fetching } = this.state;

    return (
      <>
        {!fetching && (
          <>
            {map(items, (item) => (
              <div
                className={
                  this.isActive(item.id)
                    ? `casepage-itemlist_item active rc-${item.rare}`
                    : `casepage-itemlist_item rc-${item.rare}`
                }
                style={{
                  backgroundImage: `url(/img/items/${item.id}.webp)`,
                }}
                onClick={() => this.chooseItem(item.id)}
              >
                <ItemPrice price={item.pricesInCredits?.default} />
                <span>{item.name}</span>
              </div>
            ))}
          </>
        )}
      </>
    );
  }

  render() {
    const { choosedItems, items } = this.state;

    const itemList = [];
    let textJson = '';

    if (choosedItems.length > 0) {
      for (const key in choosedItems) {
        const element = choosedItems[key];
        const findedItem = items.find((item) => item.id === element);
        itemList.push({ id: findedItem.id, rare: findedItem.rare });
      }

      textJson = JSON.stringify(itemList, undefined, 4);
    }

    return (
      <div className="inventorypage itemlistpage">
        {choosedItems.length > 0 && (
          <>
            <H2A title="" subTitle="Выбранные предметы" />
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col className="gutter-row" span={24}>
                <div
                  className="inventorypage-list choosed"
                  style={{ padding: 0 }}
                >
                  {this.renderChoosedItems()}
                </div>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col className="gutter-row" span={24}>
                <TextArea rows={4} value={textJson} />
              </Col>
            </Row>
          </>
        )}

        <H2A title="Список" subTitle="предметов" />

        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <div className="inventorypage-list" style={{ padding: 0 }}>
              {this.renderItemList()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ItemList;
