/* eslint-disable guard-for-in */
import React from 'react';
import Fade from 'react-reveal/Fade';
import {
    Alert,
    Button,
} from 'antd';
import map from 'lodash/map';
import { connect } from 'react-redux';
import H2A from '../components/mini/H2A';
import { isAuthorized } from '../helpers/Player';
import { getUserBonusHistory, activateBonus } from '../api/all/bonus';

const mapStateToProps = (state) => ({
    user: state.user,
    modules: state.modules,
});

const sendActivateBonus = (id) => {
    activateBonus(id);
};

const BonusSocialBlock = ({ data, activated }) => {
    const {
        socialTitle, url, bonus, id,
    } = data;

    return (
        <div className="bonuspage-list_block">
            <div className="bonuspage-list_block__title">{socialTitle}</div>
            <div className="bonuspage-list_block__bonus">
                {bonus}
                {' '}
                ₽
            </div>
            {activated
                ? (
                    <Button ghost disabled>
                        Выполнено
                    </Button>
                )
                : (
                    <Button type="primary" className="color-green" onClick={() => sendActivateBonus(id)}>
                        <a
                            rel="noreferrer"
                            href={url}
                        >
                            Получить бонус
                        </a>
                    </Button>
                )}

        </div>
    );
};

class Bonus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activatedBonuses: [],
        };
    }

    async componentDidMount() {
        const { user } = this.props;
        if (isAuthorized(user)) {
            await this.getHistoryActivatedBonuses();
        }
    }

    async getHistoryActivatedBonuses() {
        const bonuses = await getUserBonusHistory()
            .then(({ data }) => data);

        if (bonuses.length) {
            this.setState({
                activatedBonuses: bonuses,
            });
        }
    }

    render() {
        const { activatedBonuses } = this.state;
        const { user, modules } = this.props;

        const arrayBonus = [];

        if (Object.keys(modules.bonusList.extraData).length !== 0) {
            const list = JSON.parse(modules.bonusList.extraData);
            // eslint-disable-next-line no-restricted-syntax
            for (const key in list) {
                list[key].id = key;
                arrayBonus.push(list[key]);
            }
        }

        return (
            <Fade>
                <div className="bonuspage">

                    <H2A title="Бонусная" subTitle="Система" />

                    {!isAuthorized(user) && (
                        <Alert style={{ maxWidth: '500px', margin: '10px auto' }} message="Чтобы получить бонусы, вам нужно авторизоваться" type="error" showIcon />
                    )}
                    <div className="bonuspage-list">
                        {map(arrayBonus, (item) => (
                            <BonusSocialBlock
                                data={item}
                                activated={activatedBonuses.includes(item.id)}
                                key={`bonus${item.id}`}
                            />
                        ))}
                    </div>
                    <div className="bonuspage-text">
                        {/* Вам необходимо иметь хотя бы одно пополнение на 150 RUB,
                        с момента запуска данной системы, чтобы получить
                        бонус за подписки на социальные сети.
                        Если Вы уже подписаны,
                        то нажмите кнопку получения бонуса. Бонус за подписку выдается один раз. */}
                    </div>
                </div>
            </Fade>
        );
    }
}

export default connect(mapStateToProps, null)(Bonus);
