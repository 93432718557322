import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { logoutProfile } from '../store/actions/user';

const mapDispatchToProps = (dispatch) => ({
  logoutProfile: () => dispatch(logoutProfile()),
});

const Banned = (props) => (
  <>
    <div className="banned">
      <div>Этот аккаунт забанен на сайте!</div>
      <div>
        <Button className="color-red" onClick={() => props.logoutProfile()}>
          Выйти из аккаунта
        </Button>
      </div>
    </div>
  </>
);

export default connect(null, mapDispatchToProps)(Banned);
