import React from 'react';
import Fade from 'react-reveal/Fade';
import { Tooltip, Tabs, Button } from 'antd';
import {
  HistoryOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import { connect } from 'react-redux';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import Loader from '../components/mini/Loader';
import SendMoney from '../components/profile/SendMoney';
import { getUserById } from '../api/all/users';
import { itemInfoFetch } from '../store/actions/itemCache';
import { renderItemProp } from '../helpers/Case';
import { timeLeft } from '../helpers/Time';
import H2A from '../components/mini/H2A';
import ItemColor from '../components/mini/ItemColor';
import {
  getStorageLastItemsByUserId,
  getStorageItemsCountByUserId,
  getFavoriteCaseByUserId,
} from '../api/all/storage';

const { TabPane } = Tabs;

const mapStateToProps = (state) => ({
  itemCache: state.itemCache,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  itemInfoFetch: (id) => dispatch(itemInfoFetch(id)),
});

const loadItemsCount = 22;
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      profileData: [],
      openCaseHistory: [],
      fetching: false,
      countAllItems: 0,
      favoriteCase: null,
      tabActive: '1',
      offset: 0,
      loadMoreButton: true,
    };
  }

  // eslint-disable-next-line react/sort-comp
  getShortInfoItem(id, fieldName = null) {
    const { itemCache } = this.props;

    if (!fieldName) {
      return itemCache[id];
    }
    if (itemCache[id]) {
      return itemCache[id][fieldName];
    }

    return '';
  }

  addItemsToCache(arrayItemIds) {
    const { itemCache } = this.props;
    for (let index = 0; index < arrayItemIds.length; index++) {
      const id = arrayItemIds[index];
      if (!itemCache[id]) {
        this.props.itemInfoFetch(id);
      }
    }
  }

  async componentDidMount() {
    await this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          id: this.props.match.params.id,
        },
        () => this.getData(),
      );
    }
  }

  async loadMore() {
    const { offset, openCaseHistory, id } = this.state;
    const historyItems = await getStorageLastItemsByUserId(
      id,
      loadItemsCount,
      offset,
    ).then(({ data }) => data);

    const massiveIds = [];

    historyItems.forEach((element) => {
      massiveIds.push(element.storage_itemId);
    });

    this.addItemsToCache(massiveIds);

    this.setState({
      openCaseHistory: [...openCaseHistory, ...historyItems],
      offset: offset + loadItemsCount,
      loadMoreButton: !(historyItems.length < loadItemsCount),
    });
  }

  async getData() {
    this.setState({ fetching: true });
    const { id, offset } = this.state;
    const { history } = this.props;

    const user = await getUserById(id).then((data) => data);

    if (!user) {
      history.push('/404');
      return;
    }

    const historyItems = await getStorageLastItemsByUserId(
      id,
      loadItemsCount,
      offset,
    ).then(({ data }) => data);

    const countAllItems = await getStorageItemsCountByUserId(id).then(
      ({ data }) => data,
    );

    const favoriteCase = await getFavoriteCaseByUserId(id).then(
      ({ data }) => data,
    );

    //   const historyItems = user.openCaseHistory;
    const massiveIds = [];

    historyItems.forEach((element) => {
      massiveIds.push(element.storage_itemId);
    });

    this.addItemsToCache(massiveIds);

    this.setState({
      profileData: user.data,
      openCaseHistory: historyItems,
      fetching: false,
      countAllItems,
      favoriteCase,
      offset: loadItemsCount,
      loadMoreButton: !(historyItems.length < loadItemsCount),
    });

    this.setState({
      fetching: false,
    });
  }

  onChangeTab(activeKey) {
    if (activeKey === '2') {
      return;
    }

    this.setState({
      tabActive: activeKey,
    });
  }

  render() {
    const {
      fetching,
      profileData,
      openCaseHistory,
      countAllItems,
      favoriteCase,
      loadMoreButton,
      tabActive,
    } = this.state;
    const { user } = this.props;
    return (
      <div className="profilepage">
        {fetching ? (
          <Loader />
        ) : (
          <>
            <H2A title="Профиль" subTitle="аккаунта" />
            <h1 style={{ display: 'none' }}>
              Профиль аккаунта
              {profileData.user_login}
            </h1>
            <div className="profilepage-firstblock">
              {favoriteCase && (
                <div className="profilepage-firstblock__favoritecase">
                  <div className="profiletitle">Любимый кейс</div>
                  <Link to={`/case/${favoriteCase.case_id}`}>
                    <img src={favoriteCase.case_img} alt="кейс" />
                    <span>{favoriteCase.case_title}</span>
                  </Link>
                </div>
              )}

              <div className="profilepage-firstblock__stats">
                <div className="profiletitle">{profileData.user_login}</div>
                <div
                  className="profilepage-firstblock__stats___logo"
                  style={{
                    backgroundImage: `url(/img/avatars/${profileData.user_avatar}.webp)`,
                  }}
                />

                <div className="profilepage-firstblock__stats___info">
                  <div>
                    Кейсов открыто
                    <CountUp start={0} end={countAllItems} />
                  </div>
                  <div>
                    {timeLeft(profileData.updated_at) ? (
                      <>
                        Был на сайте
                        <span>{timeLeft(profileData.updated_at)}</span>
                      </>
                    ) : (
                      <span>Сейчас на сайте</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="profilepage-firstblock__bestdrop">
                <div className="profiletitle">Лучший дроп</div>
                <div
                  className="profilepage-firstblock__bestdrop___drop"
                  style={{ backgroundImage: 'url(/img/items/9.webp)' }}
                />
                <span>Imperator DT5</span>
              </div>
            </div>
            <div className="profilepage-secondblock">
              <Tabs
                defaultActiveKey="1"
                centered
                size="large"
                activeKey={tabActive}
                onChange={(e) => this.onChangeTab(e)}
              >
                <TabPane
                  tab={(
                    <span>
                      <HistoryOutlined />
                      История дропов
                    </span>
                  )}
                  key="1"
                >
                  <div className="casehistory-itemlist">
                    {map(openCaseHistory, (item) => (
                      <Fade key={`profileinventory${item.storage_id}`}>
                        <Tooltip
                          placement="bottom"
                          title={renderItemProp(
                            this.getShortInfoItem(item.storage_itemId, null),
                            item.storage_color,
                          )}
                        >
                          <div
                            className={
                              `casehistory-itemlist_item rc-${
                              this.getShortInfoItem(
                                item.storage_itemId,
                                'item_rare',
                              )}`
                            }
                            style={{
                              backgroundImage: `url(/img/items/${item.storage_itemId}.webp)`,
                            }}
                          >
                            <ItemColor color={item.storage_color} />
                            <span>
                              {' '}
                              {this.getShortInfoItem(
                                item.storage_itemId,
                                'item_name',
                              )}
                            </span>
                          </div>
                        </Tooltip>
                      </Fade>
                    ))}
                  </div>

                  {loadMoreButton && (
                    <div
                      className="flex-center-center"
                      style={{ marginTop: '25px' }}
                    >
                      <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        className="color-green"
                        onClick={() => this.loadMore()}
                      >
                        Загрузить еще
                      </Button>
                    </div>
                  )}
                </TabPane>

                {profileData.user_id !== user.id && (
                  <TabPane
                    tab={(
                      <SendMoney
                        nickname={profileData.user_login}
                        userIdTo={profileData.user_id}
                      />
                    )}
                    key="2"
                  />
                )}
              </Tabs>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
