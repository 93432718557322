// const rariestColors = {
//     rare: 'rgb(81, 81, 243)',
//     'very rare': 'rgb(81, 81, 243)',
//     import: 'rgb(167 0 0)',
//     exotic: 'rgb(212 183 0)',
//     'black market': 'rgb(255 0 224)',
// };

const itemColors = {
    black: '#111',
    white: '#ffffff',
    grey: '#777777',
    crimson: '#d50000',
    pink: '#ff4081',
    cobalt: '#3f51b5',
    skyblue: '#03a9f4',
    burntsienna: '#4c1100',
    saffron: '#ffeb3b',
    lime: '#7fff00',
    forestgreen: '#4caf50',
    orange: '#f4b400',
    purple: '#9c27b0',
};

module.exports = {
    itemColors,
};
