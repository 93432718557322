import React from 'react';
import PropTypes from 'prop-types';
import HeaderAlert from './HeaderAlert';

const ContentLayout = ({ children }) => (
  <>
    <HeaderAlert />
    <div className="site-layout-content">{children}</div>
  </>
);

ContentLayout.propTypes = {
  children: PropTypes.element,
};

ContentLayout.defaultProps = {
  children: 'Main page',
};

export default ContentLayout;
