const Smiles = [
  {
    name: 'EZ',
    url: 'https://cdn.betterttv.net/emote/5590b223b344e2c42a9e28e3/1x',
  },
  {
    name: 'monkaX',
    url: 'https://cdn.betterttv.net/emote/58e5abdaf3ef4c75c9c6f0f9/1x',
  },
  {
    name: 'Clap',
    url: 'https://cdn.betterttv.net/emote/55b6f480e66682f576dd94f5/1x',
  },
  {
    name: 'PepePls',
    url: 'https://cdn.betterttv.net/emote/55898e122612142e6aaa935b/1x',
  },
  {
    name: 'GachiBass',
    url: 'https://cdn.betterttv.net/emote/57719a9a6bdecd592c3ad59b/1x',
  },
  {
    name: 'OMEGALUL',
    url: 'https://cdn.betterttv.net/emote/583089f4737a8e61abb0186b/1x',
  },
  {
    name: 'FeelsGladMan',
    url: 'https://cdn.betterttv.net/emote/58ca80db994bb43c8d2ffa96/1x',
  },

  {
    name: 'monkaGun',
    url: 'https://cdn.betterttv.net/emote/58f6e05e58f5dd226a16166e/1x',
  },
  {
    name: 'monkaSHAKE',
    url: 'https://cdn.betterttv.net/emote/58d867c84c25f4458349ecc7/1x',
  },
  {
    name: 'KKool',
    url: 'https://cdn.betterttv.net/emote/56c2cff2d9ec6bf744247bf1/1x',
  },
  {
    name: 'ZULUL',
    url: 'https://cdn.betterttv.net/emote/57b38e53d5472c5343820619/1x',
  },
  {
    name: 'POGGERS',
    url: 'https://cdn.betterttv.net/emote/58ae8407ff7b7276f8e594f2/1x',
  },
  {
    name: 'pepeSmoke',
    url: 'https://cdn.betterttv.net/emote/5b15162147c7bf3bfc0b9c76/1x',
  },

  {
    name: 'sumSmash',
    url: 'https://cdn.betterttv.net/emote/5af84b9e766af63db43bf6b9/1x',
  },
  {
    name: 'ricardoFlick',
    url: 'https://cdn.betterttv.net/emote/5bc2143ea5351f40921080ee/1x',
  },
  {
    name: 'FeelsRainMan',
    url: 'https://cdn.betterttv.net/emote/57850b9df1bf2c1003a88644/1x',
  },
  {
    name: 'PepegaPls',
    url: 'https://cdn.betterttv.net/emote/5c04b07db4297124fa9d165e/1x',
  },
  {
    name: 'gachiHYPER',
    url: 'https://cdn.betterttv.net/emote/59143b496996b360ff9b807c/1x',
  },

  {
    name: 'forsenPls',
    url: 'https://cdn.betterttv.net/emote/55e2096ea6fa8b261f81b12a/1x',
  },
  {
    name: 'TriKool',
    url: 'https://cdn.betterttv.net/emote/59a6d3dedccaf930aa8f3de1/1x',
  },
  {
    name: 'HACKERMANS',
    url: 'https://cdn.betterttv.net/emote/5b490e73cf46791f8491f6f4/1x',
  },
  {
    name: 'GuitarTime',
    url: 'https://cdn.betterttv.net/emote/576befd71f520d6039622f7e/1x',
  },
  {
    name: 'peepoSad',
    url: 'https://cdn.betterttv.net/emote/5a16ddca8c22a247ead62ceb/1x',
  },
  {
    name: 'xqcSlam',
    url: 'https://cdn.betterttv.net/emote/5cb9176c750fbb4a2533fc42/1x',
  },

  {
    name: 'forsenWc',
    url: 'https://cdn.betterttv.net/emote/5d0076c7aae4851c803cbc51/1x',
  },
  {
    name: 'WAYTOODANK',
    url: 'https://cdn.betterttv.net/emote/5ad22a7096065b6c6bddf7f3/1x',
  },
  {
    name: 'peepoClap',
    url: 'https://cdn.betterttv.net/emote/5d38aaa592fc550c2d5996b8/1x',
  },
  {
    name: 'yikesJAM ',
    url: 'https://cdn.betterttv.net/emote/5cff916b2316b42d72befb43/1x',
  },
  {
    name: 'PepegaAim',
    url: 'https://cdn.betterttv.net/emote/5d0d7140ca4f4b50240ff6b4/1x',
  },
  {
    name: 'peepoSmash',
    url: 'https://cdn.betterttv.net/emote/5c72084d41600b0832ab0931/1x',
  },
  {
    name: 'pepeMeltdown',
    url: 'https://cdn.betterttv.net/emote/5ba84271c9f0f66a9efc1c86/1x',
  },
  {
    name: 'pepeJAM',
    url: 'https://cdn.betterttv.net/emote/5b77ac3af7bddc567b1d5fb2/1x',
  },
  {
    name: 'xqcDitch',
    url: 'https://cdn.betterttv.net/emote/5c6934c724de827d9bbb4723/1x',
  },
  {
    name: 'ppHop',
    url: 'https://cdn.betterttv.net/emote/5a9578d6dcf3205f57ba294f/1x',
  },
  {
    name: 'pepeD',
    url: 'https://cdn.betterttv.net/emote/5b1740221c5a6065a7bad4b5/1x',
  },
  {
    name: '3Kool',
    url: 'https://cdn.betterttv.net/emote/5e2076b1bca2995f13fb9035/1x',
  },
  {
    name: 'PepegaDriving',
    url: 'https://cdn.betterttv.net/emote/5e6fa08bd112fc3725746dd4/1x',
  },
  {
    name: 'monkaS',
    url: 'https://cdn.betterttv.net/emote/56e9f494fff3cc5c35e5287e/1x',
  },
  {
    name: 'PepeHands',
    url: 'https://cdn.betterttv.net/emote/59f27b3f4ebd8047f54dee29/1x',
  },
  {
    name: 'AYAYA',
    url: 'https://cdn.betterttv.net/emote/58493695987aab42df852e0f/1x',
  },
  {
    name: 'KEKW',
    url: 'https://cdn.betterttv.net/emote/5dae422b89488d12cc727c80/1x',
  },
  {
    name: 'HYPERS',
    url: 'https://cdn.betterttv.net/emote/5980af4e3a1ac5330e89dc76/1x',
  },
  {
    name: 'pepeLaguh',
    url: 'https://cdn.betterttv.net/emote/59b73909b27c823d5b1f6052/1x',
  },
  {
    name: 'monkaW',
    url: 'https://cdn.betterttv.net/emote/59ca6551b27c823d5b1fd872/1x',
  },
  {
    name: 'monkaHmm',
    url: 'https://cdn.betterttv.net/emote/5aa16eb65d4a424654d7e3e5/1x',
  },
  {
    name: 'monkaTOS',
    url: 'https://cdn.betterttv.net/emote/5a7fd054b694db72eac253f4/1x',
  },
  {
    name: 'KKool',
    url: 'https://cdn.betterttv.net/emote/56c2cff2d9ec6bf744247bf1/1x',
  },
  {
    name: '5Head',
    url: 'https://cdn.betterttv.net/emote/5d6096974932b21d9c332904/1x',
  },
  {
    name: 'weirdChamp',
    url: 'https://cdn.betterttv.net/emote/5d20a55de1cfde376e532972/1x',
  },
  {
    name: 'COGGERS',
    url: 'https://cdn.betterttv.net/emote/5ab6f0ece1d6391b63498774/1x',
  },
  {
    name: 'peepoClap',
    url: 'https://cdn.betterttv.net/emote/5d38aaa592fc550c2d5996b8/1x',
  },
  {
    name: 'Thonk',
    url: 'https://cdn.betterttv.net/emote/585231dd58af204561cd6036/1x',
  },
  {
    name: 'KKomrade',
    url: 'https://cdn.betterttv.net/emote/56be9fd6d9ec6bf74424760d/1x',
  },
  {
    name: 'FeelsWeirdMan',
    url: 'https://cdn.betterttv.net/emote/5603731ce5fc5eff1de93229/1x',
  },
  {
    name: 'pepeSmoke',
    url: 'https://cdn.betterttv.net/emote/5b15162147c7bf3bfc0b9c76/1x',
  },
  {
    name: 'peepoLeave',
    url: 'https://cdn.betterttv.net/emote/5d324913ff6ed36801311fd2/1x',
  },
  {
    name: 'PogU',
    url: 'https://cdn.betterttv.net/emote/5e4e7a1f08b4447d56a92967/1x',
  },
  {
    name: 'pepeClap',
    url: 'https://cdn.betterttv.net/emote/59688b35172b8b255ec3f6ac/1x',
  },
  {
    name: 'FeelsOkayMan',
    url: 'https://cdn.betterttv.net/emote/5803757f3d506fea7ee35267/1x',
  },
  {
    name: 'coronaS',
    url: 'https://cdn.betterttv.net/emote/5e3441d861ff6b51e65259fe/1x',
  },
  {
    name: 'FeelsRageMan',
    url: 'https://cdn.betterttv.net/emote/5597e66ed8152e272470f830/1x',
  },
  {
    name: 'FeelsLitMan',
    url: 'https://cdn.betterttv.net/emote/588111b6afc2ff756c3f345d/1x',
  },
  {
    name: '3Head',
    url: 'https://cdn.betterttv.net/emote/5d24f20653fb473775490d6f/1x',
  },
  {
    name: 'PepoThink',
    url: 'https://cdn.betterttv.net/emote/5a4ad2574884645e5706e51a/1x',
  },
  {
    name: 'monkaRUN',
    url: 'https://cdn.betterttv.net/emote/5c1a15869045e913a13b5410/1x',
  },
  {
    name: 'monkaCHRIST',
    url: 'https://cdn.betterttv.net/emote/5a21576595c4af067c869ba9/1x',
  },
  {
    name: 'monkaOMEGA',
    url: 'https://cdn.betterttv.net/emote/5981e21aeaab4f3320e73abe/1x',
  },
  {
    name: 'widepeepoHappy',
    url: 'https://cdn.betterttv.net/emote/5e1a76dd8af14b5f1b438c04/1x',
  },
  {
    name: 'KKonaW',
    url: 'https://cdn.betterttv.net/emote/598215f47e71d43314a80ad2/1x',
  },
  {
    name: 'Pepege',
    url: 'https://cdn.betterttv.net/emote/5c27ad590a1e447dea70e0c8/1x',
  },
  {
    name: 'DankPepe',
    url: 'https://cdn.betterttv.net/emote/55c36ec7af1e28271aa4de0a/1x',
  },
  {
    name: 'monkaSpeed',
    url: 'https://cdn.betterttv.net/emote/5b76267f0ac6031e9f61e1be/1x',
  },
  {
    name: 'sadKEK',
    url: 'https://cdn.betterttv.net/emote/5d72ae0aa32edb714a9df060/1x',
  },
  {
    name: 'LULW',
    url: 'https://cdn.betterttv.net/emote/5dc79d1b27360247dd6516ec/1x',
  },
  {
    name: 'FeelsStrongMan',
    url: 'https://cdn.betterttv.net/emote/58facc4aaffae60eac088397/1x',
  },
  {
    name: 'GIMMIE',
    url: 'https://cdn.betterttv.net/emote/5b2ab2d342872716277f4b9e/1x',
  },
  {
    name: 'peepoClown',
    url: 'https://cdn.betterttv.net/emote/5c50edcb5e0cb51b417faf37/1x',
  },
  {
    name: 'peepoBlonket',
    url: 'https://cdn.betterttv.net/emote/5b8015ba3c482e0aaa9413be/1x',
  },
  {
    name: 'peepoArrive',
    url: 'https://cdn.betterttv.net/emote/5d922afbc0652668c9e52ead/1x',
  },
  {
    name: 'PauseChamp',
    url: 'https://cdn.betterttv.net/emote/5cd6b08cf1dac14a18c4b61f/1x',
  },
  {
    name: 'forsenCD',
    url: 'https://cdn.betterttv.net/emote/5d3e250a6d68672adc3fbff7/1x',
  },
  {
    name: 'PepeS',
    url: 'https://cdn.betterttv.net/emote/59420c88023a013b50774872/1x',
  },
  {
    name: 'gachiHop',
    url: 'https://cdn.betterttv.net/emote/571e5ae5ef2b92040a9682c7/1x',
  },
  {
    name: 'BBoomer',
    url: 'https://cdn.betterttv.net/emote/5c447084f13c030e98f74f58/1x',
  },
  {
    name: 'gachiW',
    url: 'https://cdn.betterttv.net/emote/5ce66e8c1281d44f03de8051/1x',
  },
  {
    name: 'forsenSWA',
    url: 'https://cdn.betterttv.net/emote/571647c4793a158658202e2e/1x',
  },
  {
    name: 'docSpin',
    url: 'https://cdn.betterttv.net/emote/5e13c9140550d42106b8e48c/1x',
  },
  {
    name: 'pugPls',
    url: 'https://cdn.betterttv.net/emote/5de88ccef6e95977b50e6eb1/1x',
  },
  {
    name: 'PedoBear',
    url: 'https://cdn.betterttv.net/emote/54fa928f01e468494b85b54f/1x',
  },
  {
    name: 'FeelsGoodMan',
    url: 'https://cdn.betterttv.net/emote/566c9fde65dbbdab32ec053e/1x',
  },
  {
    name: 'NaM',
    url: 'https://cdn.betterttv.net/emote/566ca06065dbbdab32ec054e/1x',
  },
  {
    name: 'LuL',
    url: 'https://cdn.betterttv.net/emote/567b00c61ddbe1786688a633/1x',
  },
  {
    name: 'Okayeg',
    url: 'https://cdn.betterttv.net/emote/5de9cb6191129e77b47ca987/1x',
  },
  {
    name: 'forsenChin',
    url: 'https://cdn.betterttv.net/emote/5e5fd2df6d485d372b287fc8/1x',
  },
  {
    name: 'blobDance',
    url: 'https://cdn.betterttv.net/emote/5ada077451d4120ea3918426/1x',
  },
  {
    name: 'Pasedge',
    url: 'https://cdn.betterttv.net/emote/60c4fa84f8b3f62601c3cd28/1x',
  },
  {
    name: 'PepegaChat',
    url: 'https://cdn.betterttv.net/emote/5faf2a2ceca18f6455c320df/1x',
  },
  {
    name: 'Strongge',
    url: 'https://cdn.betterttv.net/emote/5eee000af58ddc0de9880e21/1x',
  },

  {
    name: 'pepegeChill',
    url: 'https://cdn.betterttv.net/emote/5f244bd8cf6d2144653db186/1x',
  },
  {
    name: 'pepegaGun',
    url: 'https://cdn.betterttv.net/emote/60526545306b602acc59ed61/1x',
  },

  {
    name: 'PogT',
    url: 'https://cdn.betterttv.net/emote/600ca1ac82cf6865d552d19d/1x',
  },
  {
    name: 'PepegeShrug',
    url: 'https://cdn.betterttv.net/emote/5f6cff77239b210b0de65d7d/1x',
  },
  {
    name: 'peepoLove',
    url: 'https://cdn.betterttv.net/emote/5a5e0e8d80f53146a54a516b/1x',
  },

  {
    name: 'peepoArrive',
    url: 'https://cdn.betterttv.net/emote/5d922afbc0652668c9e52ead/1x',
  },

  {
    name: 'Pepege',
    url: 'https://cdn.betterttv.net/emote/5c27ad590a1e447dea70e0c8/1x',
  },

  {
    name: 'peepoLeave',
    url: 'https://cdn.betterttv.net/emote/5d324913ff6ed36801311fd2/1x',
  },
  {
    name: 'Sadge',
    url: 'https://cdn.betterttv.net/emote/5e0fa9d40550d42106b8a489/1x',
  },
];

export default Smiles;
