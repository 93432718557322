import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const Rules = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="color-white small" onClick={showModal}>
        Правила
      </Button>
      <Modal
        title="ПРАВИЛА ЧАТА"
        visible={isModalVisible}
        cancelText="Закрыть"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
      >
        <p>
          — Запрещено использовать в никнейме название или ссылки ведущие на
          сторонний сайт.
        </p>
        <p>— Запрещено рекламировать каналы Youtube / Twitch / Discord.</p>
        <p>— Запрещено оскорблять других участников чата / сайта.</p>
        <p>
          — Запрещено упоминание платежных реквизитов в целях попрошайничества.
        </p>
        <p>
          — Запрещено распространять URL ссылки и промо-коды (кроме
          Администрации и Модераторов).
        </p>
        <p>— Запрещено спамить сообщения в чат по одному символу.</p>
        <p>
          Любой участник сайта/чата, должен с уважение относится ко всем без
          исключения участникам.
        </p>
      </Modal>
    </>
  );
};

export default Rules;
