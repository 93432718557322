import React from 'react';
import { Tooltip } from 'antd';
import publicInfo from '../../data/publicInfo';

const Socials = () => (
    <>
        <Tooltip placement="bottom" title="Вк группа">
            <a
                rel="noreferrer"
                className="headersecond-vk"
                href={publicInfo.vkGroupUrl}
                target="_blank"
            >
                <div className="headersecond-vk__vkicon" />
            </a>
        </Tooltip>
        <Tooltip placement="bottom" title="Дискорд сервер">
            <a
                rel="noreferrer"
                className="headersecond-discord"
                href={publicInfo.discordGroupUrl}
                target="_blank"
            >
                <div className="headersecond-discord__icon" />
            </a>
        </Tooltip>
    </>
);

export default Socials;
