import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import map from "lodash/map";
import { connect } from "react-redux";
import routes from "./routes";
import Layout from "../Layout";
import { getProfileFetch } from "../store/actions/user";
import { getAllModules } from "../store/actions/module";
import { isAuthorized } from "../helpers/Player";
import { profileIsOnline } from "../api/all/profile";
import Closed from "../pages/Closed";
import ErrorPage from "../pages/ErrorPage";
import Banned from "../components/Banned";
import roles from "../enum/role";

const mapDispatchToProps = (dispatch) => ({
  getProfileFetch: () => dispatch(getProfileFetch()),
  getAllModules: () => dispatch(getAllModules()),
});

const mapStateToProps = (state) => ({
  user: state.user,
  modules: state.modules,
});

class RouterLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
    };
  }

  async componentDidMount() {
    await this.props.getAllModules();
    const { modules } = this.props;
    const siteStatus = modules.siteClosed.status;
    if (siteStatus === 1) {
      // not working
      return;
    }

    await this.props.getProfileFetch();

    this.updateOnline();
    this.intervalId = setInterval(() => {
      this.updateOnline();
    }, 300000); // 5 min;
  }

  // eslint-disable-next-line react/sort-comp
  updateOnline() {
    const { user } = this.props;
    if (isAuthorized(user)) {
      profileIsOnline();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    const { user } = this.props;
    const { modules } = this.props;

    if (modules.siteClosed.status === 1) {
      const { title } = JSON.parse(modules.siteClosed.extraData);
      const { subTitle } = JSON.parse(modules.siteClosed.extraData);

      return <Closed title={title} subTitle={subTitle} />;
    }

    if (isAuthorized(user)) {
      if (roles.BANNED === user.role) {
        return <Banned />;
      }
    }

    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            {map(routes.public, (route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                breadcrumb={route.breadcrumb}
                component={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <route.component {...props} />
                )}
              />
            ))}

            {isAuthorized(user) && (
              <>
                {map(routes.private, (route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    breadcrumb={route.breadcrumb}
                    component={(props) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <route.component {...props} />
                    )}
                  />
                ))}
              </>
            )}
            <Route component={ErrorPage} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterLayout);
