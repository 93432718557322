/* eslint-disable */
import React, { useState } from "react";
import { Form, Input, Checkbox, Button, Radio } from "antd";
import images from "./../../data/avatars";
import { userPostRegisterFetch } from "../../store/actions/user";
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import { Link } from "react-router-dom";
import openNotification from "./../../components/mini/Notification";

const formItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 9 },
    sm: { span: 9 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const mapDispatchToProps = (dispatch) => ({
  userPostRegisterFetch: (userInfo) =>
    dispatch(userPostRegisterFetch(userInfo)),
});

const RegistrationForm = (props) => {
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState(1);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    values.avatar = avatar;
    props
      .userPostRegisterFetch({
        login: values.username,
        password: values.password,
        email: values.email,
        avatar: values.avatar,
      })
      .then((errMessage) => {
        setLoading(false);
        if (errMessage) {
          openNotification("error", "Ошибка", errMessage);
          return;
        }
        props.history.push("/");
        openNotification(
          "success",
          "Успешный вход",
          "Добро пожаловать на сайт " + capitalize(values.username) + "!"
        );
      });
  };

  //const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  return (
    <div className="registrationpage">
      <h1 className="title">Регистрация</h1>

      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="username"
          label="Логин"
          rules={[
            {
              required: true,
              message: "Придумайте логин логин!",
              whitespace: true,
            },
            { min: 4, message: "Минимум 4 симовола!" },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            {
              required: true,
              message: "Придумайте пароль!",
            },
            { min: 4, message: "Минимум 4 симовола!" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Подтвердите пароль"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Повторите вышевведенный пароль!!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Пароли не совпадают!"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Это не почта!",
            },
            {
              required: true,
              message: "Введите почту!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Выберите аватар">
          <Radio.Group defaultValue={avatar} buttonStyle="solid">
            {images.map((item) => (
              <Radio.Button
                value={item.id}
                className="radio-avatar"
                onClick={() => setAvatar(item.id)}
              >
                <img src={item.url} alt={item.id + " avatar"} />
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Поставьте галочку")),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox>
            Я соглашаюсь с
            <Link to="/article/3" style={{ margin: "0 5px" }}>
              пользовательским соглашением
            </Link>
            и
            <Link to="/article/6" style={{ margin: "0 5px" }}>
              правилами сайта
            </Link>
          </Checkbox>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="color-green"
          >
            Зарегистрироваться
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(RegistrationForm);
