/* eslint-disable no-restricted-syntax */
import React from 'react';
import {
 Layout, Button, Tooltip, Menu, Dropdown,
} from 'antd';
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  DropboxOutlined,
  TagOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSiteStats } from '../api/all/other';

import { isAuthorized } from '../helpers/Player';

const { Header } = Layout;

const mapStateToProps = (state) => ({
  user: state.user,
});

const menu = (id) => (
  <Menu theme="dark" className="menu-dropdown-profile">
    <Menu.Item key="7" icon={<UserOutlined />}>
      <Link to={`/profile/${id}`}>Мой профиль</Link>
    </Menu.Item>

    <Menu.Item key="15deposit" icon={<DollarOutlined />}>
      <Link to="/deposit">Пополнить счет</Link>
    </Menu.Item>

    <Menu.Item key="15" icon={<DropboxOutlined />}>
      <Link to="/inventory">Инвентарь</Link>
    </Menu.Item>
    <Menu.Item key="8" icon={<SettingOutlined />}>
      <Link to="/settings">Настройки</Link>
    </Menu.Item>

    <Menu.Item key="12" icon={<TagOutlined />}>
      <Link to="/settings">Ввести промокод</Link>
    </Menu.Item>

    <Menu.Item key="10" icon={<LogoutOutlined />}>
      <Link to="/logout">Выйти</Link>
    </Menu.Item>
  </Menu>
);
class HeaderSecond extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      openedCases: { old: 0, new: 0 },
      userCounts: { old: 0, new: 0 },
      receivedItems: { old: 0, new: 0 },
      onlineUser: { old: 0, new: 0 },
      onlineUserList: [],
      balance: this.props.user.balance,
    };

    window.HeaderSecond = this;
  }

  async componentDidMount() {
    await this.getData();

    this.setState({
      balance: this.props.user.balance,
    });

    this.intervalId = setInterval(() => {
      this.getData();
    }, 30000);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user.balance !== prevProps.user.balance) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        balance: user.balance,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  async getData() {
    const counters = await getSiteStats().then(({ data }) => data);

    const {
 openedCases, userCounts, receivedItems, onlineUser,
} = this.state;

    this.setState({
      openedCases: { old: openedCases.new, new: counters.openedCases },
      userCounts: { old: userCounts.new, new: counters.userCounts },
      receivedItems: { old: receivedItems.new, new: counters.receivedItems },
      onlineUser: { old: onlineUser.new, new: counters.onlineUser },
      onlineUserList: counters.onlineUserList,
    });
  }

  changeBalance(balance) {
    this.setState({
      balance,
    });
  }

  chatButtonStatusHeader(status) {
    this.setState({
      chatButton: status,
    });
  }

  renderUserList() {
    const arr = [];
    const { onlineUserList } = this.state;
    if (onlineUserList && !onlineUserList.length) {
      return <></>;
    }

    for (const key in onlineUserList) {
      if (Object.hasOwnProperty.call(onlineUserList, key)) {
        const element = onlineUserList[key];
        arr.push(<div>{element.user_login}</div>);
      }
    }

    return arr;
  }

  render() {
    const {
      openedCases,
      userCounts,
      receivedItems,
      onlineUser,
      balance,
      chatButton,
    } = this.state;
    const { user } = this.props;

    return (
      <Header
        className="headersecond"
        style={{ borderBottom: '1px #22262f solid' }}
      >
        <div className="headersecond-leftblock flex">
          <div className="headersecond-stats">
            <div className="headersecond-stats__opened" />
            <div className="headersecond-stats__block">
              <i>
                <CountUp start={openedCases.old} end={openedCases.new} />
              </i>
              <span>Открыто кейсов</span>
            </div>
          </div>

          <div className="headersecond-stats">
            <div className="headersecond-stats__users" />
            <div className="headersecond-stats__block">
              <i>
                <CountUp start={userCounts.old} end={userCounts.new} />
              </i>
              <span>Пользователей</span>
            </div>
          </div>

          <div className="headersecond-stats">
            <div className="headersecond-stats__exchanged" />
            <div className="headersecond-stats__block">
              <i>
                <CountUp start={receivedItems.old} end={receivedItems.new} />
              </i>
              <span>Выведено предметов</span>
            </div>
          </div>

          <div className="headersecond-stats">
            <div className="headersecond-stats__online" />
            <div className="headersecond-stats__block">
              <i>
                <CountUp start={onlineUser.old} end={onlineUser.new + 1} />
              </i>
              <Tooltip placement="bottom" title={this.renderUserList()}>
                <span>Онлайн</span>
              </Tooltip>
            </div>
          </div>
        </div>
        {/*
        <Link to="/bonus">
          <div className="headersecond-stats custom bonus">
            <GiftOutlined />
            <div className="headersecond-stats__block">
              <i>Бонусы</i>
              <span>Розыгрыши, раздачи</span>
            </div>
          </div>
        </Link> */}

        <div
          className="headersecond-rightblock flex"
          style={{ alignItems: 'center' }}
        >
          {chatButton && (
            <Button
              className="color-white small"
              style={{ marginRight: '15px' }}
              onClick={() => window.Layout.onCollapseChat(false)}
            >
              Чат
            </Button>
          )}
          {isAuthorized(user) && (
            <>
              <Link to="/deposit">
                <Button size="small" className="color-pink">
                  Пополнить
                </Button>
              </Link>

              <div className="headersecond-profile">
                <Dropdown overlay={menu(user.id)} trigger="click">
                  <Link to={`/profile/${user.id}`}>
                    <div
                      className="headersecond-profile__avatar"
                      style={{
                        backgroundImage: `url(/img/avatars/${user.avatar}.webp)`,
                      }}
                    />
                    <div className="headersecond-profile__info">
                      <span className="nickname">{user.login}</span>
                      <span className="balance">{balance}</span>
                    </div>
                  </Link>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </Header>
    );
  }
}

export default connect(mapStateToProps, null)(HeaderSecond);
