const roles = {
    NORMAL: 1,
    BANNED: -1,
    BANNED_CHAT: -2,
    YOUTUBER: 2,
    STREAMER: 3,
    FAMOUS: 4,
    ADMINISTRATOR: 4,
};

export default roles;
