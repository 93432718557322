/* eslint-disable camelcase */
import {
    LOAD_MODULES,
} from '../types';

const initialState = {
    siteClosed: { status: 0, extraData: {} },
    alertOnContent: { status: 0, extraData: {} },
    bonusList: { status: 0, extraData: {} },
    'rub-credit-rate': { status: 0, extraData: 0 },
};

const moduleReducer = (prevState = initialState, action) => {
    switch (action.type) {
    case LOAD_MODULES:
        return {
            ...prevState,
            [action.payloadKey]: action.payloadData,
        };
    default:
        return prevState;
    }
};

export default moduleReducer;
