import React, { Component } from 'react';
import {
    Row,
    Col,
    Tabs,
    Input, Button,
    Form,
} from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import H2A from '../components/mini/H2A';
import { itemInfoFetch } from '../store/actions/itemCache';
import { SUPPORT_EMAIL } from '../config/publicInfo';

const mapDispatchToProps = (dispatch) => ({
    itemInfoFetch: (id) => dispatch(itemInfoFetch(id)),
});

const { TabPane } = Tabs;

const mapStateToProps = (state) => ({
    user: state.user,
    itemCache: state.itemCache,
});

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //  tabActive: 'money',
        };

        //  this.callback = this.callback.bind(this);
        this.getSettingsPage = this.getSettingsPage.bind(this);
    }

    getSettingsPage() {
        const { history } = this.props;
        history.push('/settings');
    }

    render() {
        return (
            <div className="depositpage">
                <H2A title="Пополнение" subTitle="счета" />
                <Fade>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            <Tabs
                                defaultActiveKey="inventory"
                                tabBarExtraContent={(
                                    <Button
                                        type="primary"
                                        danger
                                        className="color-orange"
                                        onClick={() => this.getSettingsPage()}
                                    >
                                        Использовать промокод для пополнения
                                    </Button>
                                )}
                            >
                                <TabPane
                                    tab="Пополнить"
                                    key="money"
                                >
                                    <div className="depositpage-list">

                                        <div className="notworking">Недоступно</div>

                                        <div className="depositpage-list__payment">
                                            <Form layout="vertical">
                                                <Form.Item
                                                    label="ВВЕДИТЕ СУММУ (RUB)"
                                                >
                                                    <Input
                                                        size="large"
                                                        prefix={<DollarOutlined className="site-form-item-icon" />}
                                                    />
                                                </Form.Item>

                                                <Button
                                                    type="primary"
                                                    danger
                                                    size="large"
                                                    className="color-green"
                                                    onClick={() => this.getSettingsPage()}
                                                >
                                                    Пополнить баланс
                                                </Button>
                                            </Form>
                                        </div>

                                        <div className="depositpage-list__paymentlist">
                                            Для пополнения баланса вы будете перемещены на сайт
                                            платёжной системы.
                                            Баланс пополняется моментально, но, если этого не
                                            произошло в течение часа, напишите нам на почту
                                            {' '}
                                            {SUPPORT_EMAIL}
                                            , указав подробные данные платежа.
                                        </div>

                                    </div>
                                </TabPane>
                                <TabPane tab="История пополнений" key="history">
                                    <div className="depositpage-list">
                                        <div className="notworking">Недоступно</div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Fade>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
