/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import RouterLayout from "./router/RouterLayout.jsx";
import "./view/style.scss";
import "./view/responsive.scss";
import store from "./store/index";
import { Provider } from "react-redux";

ReactDOM.render(
  <Provider store={store}>
    <RouterLayout />
  </Provider>,
  document.getElementById("root")
);
