import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import map from 'lodash/map';
import Loader from '../components/mini/Loader';
import H2A from '../components/mini/H2A';
import { getArticleById } from '../api/all/articles';
import { timeConverterDDMMYY } from '../helpers/Time';

export default class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      articleData: [],
      fetching: false,
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          id: this.props.match.params.id,
        },
        () => this.getData(),
      );
    }
  }

  async getData() {
    this.setState({ fetching: true });
    const { id } = this.state;
    const { history } = this.props;

    const article = await getArticleById(id).then(({ data }) => data);

    if (!article) {
      history.push('/404');
      return;
    }

    this.setState({
      articleData: [article],
      fetching: false,
    });
  }

  render() {
    const { articleData, fetching } = this.state;
    return (
      <div className="articlepage">
        {fetching ? (
          <Loader />
        ) : (
          <>
            {map(articleData, (item) => (
              <Fade key={`article${item.article_title}`}>
                <H2A title={item.article_title} subTitle="" />
                <h1 style={{ display: 'none' }}>{item.article_title}</h1>
                <div className="articlepage-content">
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.article_text,
                    }}
                  />
                  <div className="extra-row">
                    <div>
                      <span>Создано:</span>
                      <span>{timeConverterDDMMYY(item.created_at)}</span>
                    </div>
                    <div>
                      <span>Отредактировано:</span>
                      <span>{timeConverterDDMMYY(item.updated_at)}</span>
                    </div>
                    {/*
                                        <div>
                                            <span>Просмотров:</span>
                                            <span>
                                                {item.article_views + 1}
                                            </span>
                                        </div> */}
                  </div>
                </div>
              </Fade>
            ))}
          </>
        )}
      </div>
    );
  }
}
