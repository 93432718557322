/* eslint-disable */
import React, { useState } from "react";
import { Form, Input, Button, Divider } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import ForgotPassword from "./ForgotPassword";
import { Link } from "react-router-dom";
import { userPostFetch } from "../../store/actions/user";
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import openNotification from "./../../components/mini/Notification";

const mapDispatchToProps = (dispatch) => ({
  userPostFetch: (userInfo) => dispatch(userPostFetch(userInfo)),
});

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    props
      .userPostFetch({
        login: values.username,
        password: values.password,
      })
      .then((errMessage) => {
        setLoading(false);
        if (errMessage) {
          openNotification("error", "Ошибка", "Данные не верны");
          return;
        }
        props.history.push("/");
        openNotification(
          "success",
          "Успешный вход",
          "Добро пожаловать на сайт " + capitalize(values.username) + "!"
        );
      });
  };

  return (
    <div className="loginpage">
      <h1 className="title">Авторизация</h1>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="username"
          label="Логин"
          rules={[
            { required: true, message: "Введите ваш логин!" },
            { min: 4, message: "Минимум 4 симовола!" },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            { required: true, message: "Введите ваш пароль!" },
            { min: 4, message: "Минимум 4 симовола!" },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button color-green"
            style={{ marginRight: "15px" }}
            loading={loading}
          >
            Войти
          </Button>
          <Link to="/registration">
            <Button
              type="primary"
              className="color-purple"
              style={{ marginRight: "15px" }}
              loading={loading}
            >
              Зарегистрироваться
            </Button>
          </Link>
          <ForgotPassword />
        </Form.Item>
      </Form>
      {/*
     <div className="loginpage-types"> 
      <Divider>Или войти через</Divider>
      <div className="loginpage-types__buttons">
        <Button type="ghost" htmlType="submit">
             Steam
        </Button>
        <Button type="ghost" htmlType="submit">
              VKontakte
        </Button>
        <Button type="ghost" htmlType="submit">
              Facebook
        </Button>  
        <Button type="ghost" htmlType="submit">
              Gmail
        </Button>
        <Button type="ghost" htmlType="submit">
              Mail.ru
        </Button>
      </div>
     </div>*/}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Login);
