import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  QuestionCircleOutlined,
  UserOutlined,
  BarChartOutlined,
  CodeSandboxOutlined,
  GiftOutlined, TagsOutlined,
} from '@ant-design/icons';

import { connect } from 'react-redux';
import Socials from './mini/Socials';

import { isAuthorized } from '../helpers/Player';

const mapStateToProps = (state) => ({
  user: state.user,
});

const defaultMenu = [
    {
     key: 'cases', link: '/', label: 'Кейсы', icon: <CodeSandboxOutlined />,
    },
    {
      key: 'topplayers', link: '/top', label: 'Топ игроков', icon: <BarChartOutlined />,
    },
    {
      key: 'bonus', link: '/bonus', label: 'Бонусы', icon: <GiftOutlined />,
    },
    {
      key: 'faq', link: '/article/1', label: 'FAQ', icon: <QuestionCircleOutlined />,
    },
    {
      key: 'moneynotenough', link: '/article/8', label: 'Еще денег', icon: <TagsOutlined />,
    },
//    {
//      key: 'casewar', link: '/casewar', label: 'Битва кейсов', icon: <SlidersOutlined />,
//    },,
//    {
//      key: 'coinflip', link: '/coinflip', label: 'Coinflip', icon: <RocketOutlined />,
//    },
];

class MenuLayoutSider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { user } = this.props;
    return (
      <>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['cases']}>
          {defaultMenu.map((menu) => (
            <Menu.Item key={menu.key} icon={menu.icon}>
              <Link to={menu.link}>{menu.label}</Link>
            </Menu.Item>
          ))}

          {!isAuthorized(user) && (
            <Menu.Item key="6" icon={<UserOutlined />}>
              <Link to="/login">Войти</Link>
            </Menu.Item>
          )}
        </Menu>

        <div className="custom socials">
          <Socials />
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(MenuLayoutSider);
