import React from 'react';
import { Layout, Tooltip, Popover } from 'antd';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ItemColor from './mini/ItemColor';
import { renderItemProp } from '../helpers/Case';
import { getStorageLastItemsWithUserInfo } from '../api/all/storage';
import { itemInfoFetch } from '../store/actions/itemCache';

const { Header } = Layout;

const mapStateToProps = (state) => ({
  itemCache: state.itemCache,
});

const mapDispatchToProps = (dispatch) => ({
  itemInfoFetch: (id) => dispatch(itemInfoFetch(id)),
});

const ProfileInline = ({ data }) => (
  <Link to={`/profile/${data.user_id}`} className="popover-history-user">
    <div
      className="popover-history-user__avatar"
      style={{
        backgroundImage: `url(/img/avatars/${data.user_avatar}.webp)`,
      }}
    />
    <span>{data.user_login}</span>
  </Link>
);

const CaseInfo = ({ data }) => (
  <Link to={`/case/${data.case_id}`} className="popover-history-case">
    <img className="case-img" src={data.case_img} alt={data.case_title} />
    <div className="case-name">{data.case_title}</div>
  </Link>
);

class HeaderThird extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storageLast: [],
      intervalId: null,
      userList: {},
      caseList: {},
    };
  }

  async componentDidMount() {
    this.mounted = true;

    await this.getData();

    this.intervalId = setInterval(() => {
      this.getData();
    }, 15000);
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.state.intervalId);
  }

  // eslint-disable-next-line react/sort-comp
  getShortInfoItem(id, fieldName = null) {
    const { itemCache } = this.props;

    if (fieldName === null) {
      return itemCache[id];
    }
    if (itemCache[id]) {
      return itemCache[id][fieldName];
    }

    return '';
  }

  // eslint-disable-next-line react/sort-comp
  addItemsToCache(arrayItemIds) {
    const { itemCache } = this.props;
    for (let index = 0; index < arrayItemIds.length; index++) {
      const id = arrayItemIds[index];
      if (!itemCache[id]) {
        this.props.itemInfoFetch(id);
      }
    }
  }

  async getData() {
    const limit = 25;
    await getStorageLastItemsWithUserInfo(limit).then((data) => {
      if (this.mounted) {
        const lastItems = data.data;
        const { userList, caseList } = data;

        const massiveIds = [];

        lastItems.forEach((element) => {
          massiveIds.push(element.storage_itemId);
        });

        this.addItemsToCache(massiveIds);
        this.setState({
          storageLast: lastItems,
          userList,
          caseList,
        });
      }
      return null;
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Error', err);
    });
  }

  render() {
    const { storageLast, userList, caseList } = this.state;

    return (
      <Header className="headersecond third">
        {map(storageLast, (item, i) => (
          <div
          key={`header ${item.storage_id}`}
          >
            {i < 24 && (
              <Tooltip
                placement="right"
                title={renderItemProp(
                  this.getShortInfoItem(item.storage_itemId, null),
                  item.storage_color,
                )}
              >
                <Link
                to={`/profile/${item.storage_userId}`}
                >
                  <Popover
                    placement="bottom"
                    content={<CaseInfo data={caseList[item.storage_caseId]} />}
                    title={
                      <ProfileInline data={userList[item.storage_userId]} />
                    }
                  >
                    <div
                      className={
                        `casepage-itemlist_item r-${
                        this.getShortInfoItem(item.storage_itemId, 'item_rare')}`
                      }
                      style={{
                        backgroundImage: `url(/img/items/${item.storage_itemId}.webp)`,
                        // borderColor: getItemColor(item.storage_color),
                      }}
                    >
                      <ItemColor color={item.storage_color} />
                    </div>
                  </Popover>
                </Link>
              </Tooltip>
            )}
          </div>
        ))}
      </Header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderThird);
