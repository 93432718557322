/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Button, Alert, Radio } from 'antd';
import styled, { keyframes } from 'styled-components';
import {
  DollarOutlined,
  RollbackOutlined,
  UnlockOutlined,
  BlockOutlined,
  DropboxOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isAuthorized } from '../../helpers/Player';
import { openCaseById } from '../../api/all/cases';
import { sellItemByStorageId } from '../../api/all/storage';
import { getItemPriceById } from '../../api/all/item';
import openNotification from '../mini/Notification';
import { itemInfoFetch } from '../../store/actions/itemCache';
import ItemColor from '../mini/ItemColor';
import H2A from '../mini/H2A';
import CasePrice from '../mini/CasePrice';

const delayAnimation = 4; // s

const moveVertically = (top) => keyframes`
  0% {
    transform: translateY(-140px) 
  }
  20% {
    transform: translateY(-100px) 
  }
  100% {
    transform: translateY(${top}px)
  }
`;

const ShadowOverlay = styled.div`
  transform: translateY(${(p) => (p.load ? p.top : -140)}px);
  animation-timing-function: ease;
  animation: ${(p) => (p.load ? moveVertically(p.top) : 'none')}
    ${delayAnimation}s ease;
  animation-fill-mode: forwards;
`;

const randomInteger = (min, max) => {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
};

const mapDispatchToProps = (dispatch) => ({
  itemInfoFetch: (id) => dispatch(itemInfoFetch(id)),
});

const mapStateToProps = (state) => ({
  user: state.user,
  itemCache: state.itemCache,
  modules: state.modules,
});

  // react/sort-comp
const calculatePositionForLine = (winner) => {
    const heightOneBlock = 110; // 110px
    const resultTopPixel = -heightOneBlock * (winner.winIndex - 1)
      + 270
      + winner.winIndex
      - randomInteger(5, heightOneBlock - 5);

    return resultTopPixel;
};

class OpenCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMethod: '',
      randomItemsList: [],
      winner: null,
      load: false,
      loadItem: false,
      loadIndex: null,
      clicked: false,
      processWorking: false,
      loading: false,
      openCount: '1',
    };

    this.openBlockMethod = this.openBlockMethod.bind(this);
    this.openScrollMethod = this.openScrollMethod.bind(this);
    this.getBack = this.getBack.bind(this);
    this.getLoginPage = this.getLoginPage.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  getShortInfoItem(id, fieldName) {
    const { itemCache } = this.props;
    if (itemCache[id]) {
      return itemCache[id][fieldName];
    }

    return '';
  }

  // shouldComponentUpdate() {
  //     if (this.state.processWorking) {
  //         return false;
  //     }
  //     return true;
  //   }

  async openFastMethod() {
    const { processWorking, loading } = this.state;
    if (processWorking || loading) {
      return;
    }

    this.setState({
      processWorking: true,
      loading: true,
    });

    const result = await this.open();

    if (!result) {
      return;
    }

    this.setState({
      load: true,
      openMethod: '',
      processWorking: false,
      loadItem: true,
      loading: false,
    });
  }

  // eslint-disable-next-line react/sort-comp
  getBack() {
    this.setState({
      openMethod: '',
      load: false,
      randomItemsList: [],
      winner: null,
      loadIndex: null,
      loadItem: false,
      loadAll: false,
      clicked: false,
      openCount: '1',
      prices: [],
    });
  }

  getLoginPage() {
    const { history } = this.props;
    if (history) {
      history.push('/login');
    }
  }

  async sellItem(storageId, i, notify = true) {
    const { prices } = this.state;

    const result = await sellItemByStorageId(storageId);
    if (result.status === 200) {
      window.HeaderSecond.changeBalance(result.balance);
      if (notify) {
        openNotification('success', 'Предмет продан');
      }
      delete prices[i];
      this.setState({
        prices,
      });
      return;
    }
    if (notify) {
      openNotification('error', 'Произошла ошибка');
    }
  }

  async open() {
    const { data } = this.props;
    const { openCount } = this.state;
    if (
      data.case_openLimit <= data.case_openedCount
      && data.case_openLimit !== 0
    ) {
      this.setState({
        load: false,
        openMethod: '',
        processWorking: false,
        loadItem: false,
        loading: false,
      });
      openNotification(
        'error',
        'У этого кейса достиг лимит открытий. Этот кейс больше нельзя открыть!',
      );
      return false;
    }

    const result = await openCaseById(
      data.case_id,
      parseInt(openCount, 10),
    ).then((res) => res);

    if (!result) return false;

    if (!result.balance) {
      openNotification('error', 'Ошибка', result.message);
      this.setState({
        loading: false,
      });
      return false;
    }

    const actualPricesD = [];

    for (const key in result.data) {
      const element = result.data[key];

      // eslint-disable-next-line no-await-in-loop
      const itemPrice = await getItemPriceById(element.winner.item.id);
      const prices = JSON.parse(itemPrice.prices);
      let { color } = element.winner.item;
      color = color.toLowerCase();
      color = color.replace(' ', '');

      const creditToRub = this.props.modules['rub-credit-rate'].extraData;

      let actualPrice = null;
      if (prices) {
        if (prices[color]) {
          actualPrice = parseInt(prices[color] * creditToRub * 100, 10) / 100;
        }
      }
      actualPricesD.push(actualPrice);
    }

    const randomList = [];
    const winnerList = [];

    for (const key in result.data) {
      const element = result.data[key];
      randomList.push(element.resultWithItem);
      winnerList.push(element.winner);
    }

    this.setState({
      // load: true,
      randomItemsList: randomList,
      winner: winnerList,
      prices: actualPricesD,
      // actualPrice: actualPricesD,
    });

    window.HeaderSecond.changeBalance(result.balance);
    return true;
  }

  onChangeRadio(e) {
    const { value } = e.target; // For disable radio
    this.setState({ openCount: value });
  }

  getSummAll() {
    const { prices } = this.state;
    let sum = 0.0;

    for (let index = 0; index < prices.length; index++) {
      const element = parseFloat(prices[index]);
      if (element) {
        sum += element;
      }
    }

    if (sum === 0.0) {
      return null;
    }

    return sum.toFixed(2);
  }

  async openScrollMethod() {
    const { processWorking, loading } = this.state;
    if (processWorking || loading) {
      return;
    }

    this.setState({
      processWorking: true,
      loading: true,
    });

    const result = await this.open();

    if (!result) {
      return;
    }

    this.setState({
      openMethod: 'scroll',
      load: true,
    });

    setTimeout(() => {
      this.setState({
        loadItem: true,
        openMethod: '',
        processWorking: false,
        loading: false,
      });
    }, delayAnimation * 1000 + 2000);
  }

  async openBlockMethod(index = null) {
    const { processWorking, loading } = this.state;
    if (processWorking || loading) {
      return;
    }

    this.setState({
      openMethod: 'block',
      load: true,
    });

    if (index === null || this.state.clicked) {
      return;
    }

    this.setState({
      openMethod: 'block',
      load: true,
      loading: true,
    });

    this.setState({
      processWorking: true,
    });

    const result = await this.open();

    if (!result) {
      return;
    }

    this.setState({
      loadIndex: index,
      clicked: true,
    });

    setTimeout(() => {
      this.setState({
        loadAll: true,
      });
    }, 1000);

    setTimeout(() => {
      this.setState({
        openMethod: '',
        loadItem: true,
        clicked: false,
        processWorking: false,
        loading: false,
      });
    }, 3000);
  }

  addItemsToCache(arrayItemIds) {
    const { itemCache } = this.props;
    for (let index = 0; index < arrayItemIds.length; index++) {
      const id = arrayItemIds[index];
      if (!itemCache[id]) {
        this.props.itemInfoFetch(id);
      }
    }
  }

  async sellItemAll() {
    const { winner } = this.state; // WinnerLost
    const storageIds = [];

    for (const key in winner) {
      storageIds.push(winner[key].storageId);
    }

    const promises = [];
    for (let index = 0; index < storageIds.length; index++) {
      const element = storageIds[index];
      // eslint-disable-next-line no-await-in-loop
      promises.push(await this.sellItem(element, index, false));
    }

    await Promise.all(promises);
    openNotification('success', 'Предметы продан');
  }

  // eslint-disable-next-line class-methods-use-this
  renderBlocks() {
    const massive = [];
    const max = 22; // full 22
    const randomItemsList = this.state.randomItemsList[0];

    const newMassive = [];

    if (randomItemsList) {
      const { loadIndex } = this.state;
      const { winIndex } = this.state.winner;
      const startRandomList = winIndex - loadIndex - 1;
      for (
        let index = startRandomList;
        index < randomItemsList.length;
        index++
      ) {
        newMassive.push(randomItemsList[index]);
      }

      if (newMassive.length < max) {
        for (let index = 0; index < 100; index++) {
          newMassive.push(randomItemsList[index]);

          if (newMassive.length === max) {
            break;
          }
        }
      }
    }

    for (let index = 0; index < max; index++) {
      const item = newMassive[index] || {};
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      massive.push(
        <div
          className={
            index === this.state.loadIndex || this.state.loadAll
              ? 'loterylist opened'
              : 'loterylist'
          }
        >
          <div className="front" onClick={() => this.openBlockMethod(index)} />
          <div className="back">
            {newMassive.length && (
              <div
                className={`casepage-itemlist_item rc-${this.getShortInfoItem(
                  item.id,
                  'item_rare',
                )}`}
                style={{
                  backgroundImage: `url(/img/items/${item.id}.webp)`,
                }}
              >
                <ItemColor color={item.color} />
                <span>{this.getShortInfoItem(item.id, 'item_name')}</span>
              </div>
            )}
          </div>
        </div>,
      );
    }
    return massive;
  }

  render() {
    const {
      randomItemsList,
      load,
      loadItem,
      winner,
      openMethod,
      clicked,
      loading,
      openCount,
      prices,
    } = this.state;
    const { data, user } = this.props;

    return (
      <>
        {isAuthorized(user) ? (
          <>
            {!load && (
              <>
                <div className="casepage-more">
                  <Fade>
                    <div />
                    <H2A title="Кейс" subTitle={data.case_title} />

                    <img src={data.case_img} alt={data.case_title} />
                  </Fade>
                  <div>
                    <CasePrice data={data} count={openCount} />
                  </div>
                  <div className="count-buttons">
                    <Radio.Group
                      buttonStyle="solid"
                      defaultValue={openCount}
                      onChange={(e) => this.onChangeRadio(e)}
                      value={openCount}
                    >
                      <Radio.Button value="1">1</Radio.Button>
                      <Radio.Button value="2">2</Radio.Button>
                      <Radio.Button value="3">3</Radio.Button>
                      <Radio.Button value="4">4</Radio.Button>
                      <Radio.Button value="5">5</Radio.Button>
                      <Radio.Button value="10">10</Radio.Button>
                      <Radio.Button value="20">20</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>

                <Button
                  onClick={() => this.openScrollMethod()}
                  size="large"
                  type="primary"
                  icon={<UnlockOutlined />}
                  style={{ marginRight: 10 }}
                  loading={loading}
                  className="color-purple"
                >
                  Открыть прокруткой
                </Button>
                {openCount === '1' && (
                  <Button
                    onClick={() => this.openBlockMethod()}
                    size="large"
                    type="primary"
                    icon={<BlockOutlined />}
                    style={{ marginRight: 10 }}
                    danger
                    loading={loading}
                    className="color-red"
                  >
                    Открыть блоком
                  </Button>
                )}

                <Button
                  onClick={() => this.openFastMethod()}
                  size="large"
                  type="primary"
                  danger
                  icon={<ThunderboltOutlined />}
                  className="color-orange"
                  loading={loading}
                >
                  Быстро
                </Button>
              </>
            )}

            {load && (
              <div className="opencase">
                {loadItem && (
                  <>
                    {map(winner, (winnerItem, i) => (
                      <div key={`winneritem${winnerItem.storageId}`}>
                        <div className="opencase-result">
                          <div
                            className={`opencase-result__img rc-${this.getShortInfoItem(
                              winnerItem.item.id,
                              'item_rare',
                            )}`}
                            style={{
                              backgroundImage: `url(/img/items/${winnerItem.item.id}.webp)`,
                            }}
                          >
                            <ItemColor color={winnerItem.item.color} />
                          </div>
                          <span>
                            {this.getShortInfoItem(
                              winnerItem.item.id,
                              'item_rare',
                            )}
{' '}
                            {this.getShortInfoItem(
                              winnerItem.item.id,
                              'item_type',
                            )}
                          </span>
                          <div
                            className={
                              this.getSummAll()
                                ? 'opencase-result_name'
                                : 'opencase-result_name-selled'
                            }
                          >
                            {this.getShortInfoItem(
                              winnerItem.item.id,
                              'item_name',
                            )}
                          </div>

                          <div className="opencase-result__buttons">
                            {prices[i] && (
                              <Button
                                type="primary"
                                icon={<DollarOutlined />}
                                size="large"
                                className="color-green"
                                onClick={() => this.sellItem(winnerItem.storageId, i)}
                              >
                                Продать за
{' '}
{prices[i]}
{' '}
₽
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="buttons-back">
                      <Button
                        type="primary"
                        icon={<RollbackOutlined />}
                        size="large"
                        danger
                        onClick={() => this.getBack()}
                        className="color-grey"
                      >
                        Вернуться
                      </Button>

                      <Link to="/inventory" style={{ display: 'flex' }}>
                        <Button
                          type="primary"
                          icon={<DropboxOutlined />}
                          size="large"
                          className="color-skyblue"
                        >
                          Перейти в инвентарь
                        </Button>
{' '}
                      </Link>
                      {this.getSummAll() && prices.length > 1 && (
                        <Button
                          type="primary"
                          icon={<DollarOutlined />}
                          size="large"
                          className="color-green"
                          onClick={() => this.sellItemAll()}
                        >
                          Продать все за
{' '}
{this.getSummAll()}
{' '}
₽
                        </Button>
                      )}
                    </div>
                  </>
                )}

                {openMethod === 'scroll' && (
                  <>
                    {map(randomItemsList, (randomItemsListItem, i) => (
                      <div key={`opencase-block ${i}`}>
                        {randomItemsList.length === 1 && (
                          <Zoom left>
                            <div className="opencase-titlecrate" key="opencase-titlecrate-key">
                              Открытие
{' '}
<span>
Кейс -
{data.case_title}
</span>
                            </div>
                          </Zoom>
                        )}
                        {i === 1
                           && (
                          <Zoom>
                            <div
                              className="case-overlay"
                              style={{
                                backgroundImage: `url(${data.case_img})`,
                              }}
                            />
                          </Zoom>
                        )}

                        {randomItemsList.length === 1 && (
                          <>
                            <Fade delay={delayAnimation * 1000 + 500}>
                              <div className="opencase-titleitem">
                                {this.getShortInfoItem(
                                  winner[i].item.id,
                                  'item_name',
                                )}
                                <span>
                                  {this.getShortInfoItem(
                                    winner[i].item.id,
                                    'item_rare',
                                  )}
{' '}
                                  {this.getShortInfoItem(
                                    winner[i].item.id,
                                    'item_type',
                                  )}
                                </span>
                              </div>
                            </Fade>
{' '}
                          </>
                        )}

                        <div className="opencase-block">
                          <div className="line-overlay" />

                          <div className="shadow-overlay">
                            <ShadowOverlay
                              load={load}
                              top={calculatePositionForLine(winner[i])}
                            >
                              {map(randomItemsListItem, (item, ind) => (
                                <div
                                key={`openedItem${ind}`}
                                >
                                  <div
                                    className={
                                      i === winner[i].winIndex - 1
                                        ? `item active rc-${this.getShortInfoItem(
                                            winner[i].item.id,
                                            'item_rare',
                                          )}`
                                        : `item rc-${this.getShortInfoItem(
                                            item.id,
                                            'item_rare',
                                          )}`
                                    }
                                    style={{
                                      backgroundImage: `url(/img/items/${item.id}.webp)`,
                                    }}
                                  >
                                    <ItemColor color={item.color} />
                                  </div>
                                </div>
                              ))}
                            </ShadowOverlay>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {openMethod === 'block' && (
                  <>
                    <span className="casepage-title-second">
                      Выберите любой блок чтобы открыть кейс
                    </span>
                    <div className="casepage-itemlist">
                      <Fade>{this.renderBlocks()}</Fade>
                    </div>

                    {!clicked && (
                      <Button
                        type="primary"
                        icon={<RollbackOutlined />}
                        size="large"
                        style={{ marginTop: 20 }}
                        danger
                        onClick={() => this.getBack()}
                        className="color-grey"
                      >
                        Вернуться
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="casepage-more">
              <Fade>
                <H2A title="Кейс" subTitle={data.case_title} />
                <img src={data.case_img} alt={data.case_title} />
                <CasePrice data={data} count={openCount} />
              </Fade>
            </div>

            <div className="alert-case-auth">
              <Alert
                message="Для открытия кейсов необходимо пройти авторизацию"
                type="error"
                showIcon
              />
            </div>

            <Link to="/login">
              <Button
                onClick={() => this.getLoginPage()}
                size="large"
                type="primary"
                danger
                icon={<ThunderboltOutlined />}
                className="color-red"
              >
                Авторизоваться
              </Button>
            </Link>
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenCase);
