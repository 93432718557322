import React from 'react';
import { Redirect } from 'react-router-dom';
import Layout from '../Layout';
import Article from '../pages/Article';
import Cases from '../pages/Cases';
import Case from '../pages/Case';
import ErrorPage from '../pages/ErrorPage';
import Login from '../pages/Auth/Login';
import Registration from '../pages/Auth/Registration';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import Inventory from '../pages/Inventory';
import Deposit from '../pages/Deposit';
import Logout from '../pages/Auth/Logout';
import Bonus from '../pages/Bonus';
import Top from '../pages/Top';
// import CoinFlip from "../pages/CoinFlip";
import ItemList from '../pages/ItemList';

// import CaseWar from "../pages/CaseWar";

export default {
  private: [
    {
      path: '/logout',
      layout: Layout,
      component: Logout,
      breadcrumb: 'Выход из аккаунта',
      exact: true,
    },
    /* {
      path: "/casewar",
      exact: true,
      layout: Layout,
      breadcrumb: "Битва кейсов",
      component: CaseWar,
    }, */
    {
      path: '/settings',
      exact: true,
      layout: Layout,
      breadcrumb: 'Профиль',
      component: Settings,
    },
    {
      path: '/inventory',
      exact: true,
      layout: Layout,
      breadcrumb: 'Инвентарь',
      component: Inventory,
    },
    {
      path: '/deposit',
      exact: true,
      layout: Layout,
      breadcrumb: 'Пополнить счет',
      component: Deposit,
    },
  ],
  public: [
    {
      path: '/',
      layout: Layout,
      component: Cases,
      breadcrumb: 'Главная',
      exact: true,
    },
    /* {
      path: "/coinflip",
      exact: true,
      layout: Layout,
      breadcrumb: "Битва коин флип",
      component: CoinFlip,
    }, */
    {
      path: '/login',
      layout: Layout,
      component: Login,
      breadcrumb: 'Авторизация',
      exact: true,
    },
    {
      path: '/bonus',
      layout: Layout,
      component: Bonus,
      breadcrumb: 'Бонус',
      exact: true,
    },
    {
      path: '/top',
      layout: Layout,
      component: Top,
      breadcrumb: 'Топ',
      exact: true,
    },
    {
      path: '/registration',
      layout: Layout,
      component: Registration,
      breadcrumb: 'Registration',
      exact: true,
    },
    {
      path: '/404',
      exact: true,
      layout: Layout,
      breadcrumb: 'Страница не найдена',
      component: ErrorPage,
    },
    {
      path: '/article/:id',
      layout: Layout,
      component: Article,
      breadcrumb: 'Статья',
    },
    {
      path: '/article',
      exact: true,
      layout: Layout,
      breadcrumb: 'Кейсы',
      component: () => <Redirect to="/404" />,
    },
    {
      path: '/case/:id',
      layout: Layout,
      component: Case,
      breadcrumb: 'Кейс',
    },
    {
      path: '/case',
      exact: true,
      layout: Layout,
      breadcrumb: 'Кейсы',
      component: () => <Redirect to="/" />,
    },
    {
      path: '/profile/:id',
      layout: Layout,
      component: Profile,
      breadcrumb: 'Профиль',
    },
    {
      path: '/profile',
      exact: true,
      layout: Layout,
      breadcrumb: 'Профиль',
      component: () => <Redirect to="/404" />,
    },
    // For me
    {
      path: '/itemlist',
      exact: true,
      layout: Layout,
      breadcrumb: 'Список предметов',
      component: ItemList,
    },
  ],
};
