import React, { Component } from 'react';
import {
 Form, Input, Button, Card, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { updateProfileField } from '../../store/actions/user';
import openNotification from '../mini/Notification';

const mapDispatchToProps = (dispatch) => ({
  updateProfileField: (fieldName, fieldData) => dispatch(updateProfileField(fieldName, fieldData)),
});

const mapStateToProps = (state) => ({
  user: state.user,
});

class ProfileReceiveInfo extends Component {
  onFinish(e) {
    if (!e.receiveInfo) {
      openNotification('error', 'Ошибка', 'Не оставляйте поле пустым');
      return;
    }

    this.props.updateProfileField('receiveInfo', e.receiveInfo);
    openNotification('success', 'Сохранено');
  }

  render() {
    const { receiveInfo } = this.props.user;
    return (
      <div>
        <Card
          type="inner"
          title="Информация для вывода предметов"
          className="blockstyle-first receiveinfo"
          extra={(
            <Tooltip
              placement="top"
              title="Оставьте ссылку в Steam или EpicID, и удобное время для вас. Эта информация будет отправлена трейдерам."
            >
              Что это?
            </Tooltip>
          )}
        >
          <Form
            initialValues={{ remember: true }}
            onFinish={(e) => this.onFinish(e)}
            scrollToFirstError
            layout="inline"
          >
            <Form.Item name="receiveInfo">
              <Input.TextArea
                placeholder="Укажите ваш Steam или EpicId"
                defaultValue={receiveInfo}
                style={{ minHeight: '117px' }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="color-green">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileReceiveInfo);
