const inventoryStatus = {
    inventory: 'В инвентаре',
    received: 'Предмет выведен',
    waitingtrade: 'Ожидается вывод',
    money: 'Обменяли на деньги',
};

module.exports = {
    inventoryStatus,
};
