// User
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD';

// Items Cache
export const ADD_ITEMS_CACHE = 'ADD_ITEMS_CACHE';

// Modules
export const LOAD_MODULES = 'LOAD_MODULES';
