/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import {
 Row, Col, Alert, Tabs, Tooltip, Button,
} from 'antd';
import map from 'lodash/map';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getProfileStorage, sellItemByStorageId } from '../api/all/storage';
import H2A from '../components/mini/H2A';
import { renderItemProp } from '../helpers/Case';
import { itemInfoFetch } from '../store/actions/itemCache';
import ItemColor from '../components/mini/ItemColor';
import ItemOptions from '../components/inventory/ItemOptions';
import { timeLeft } from '../helpers/Time';

import openNotification from '../components/mini/Notification';

const mapDispatchToProps = (dispatch) => ({
  itemInfoFetch: (id) => dispatch(itemInfoFetch(id)),
});

const { TabPane } = Tabs;

const mapStateToProps = (state) => ({
  user: state.user,
  itemCache: state.itemCache,
  modules: state.modules,
});

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      choosedItem: null,
      fetching: false,
      tabActive: 'inventory',
      choosedItems: [],
      chooseMode: false,
    };

    window.Inventory = this;
    this.callback = this.callback.bind(this);
  }

  componentDidMount() {
    this.getData('inventory');
  }

  async getData(key) {
    this.setState({ fetching: true });
    const body = { status: key }; // пока пусто

    const items = await getProfileStorage(body).then(({ data }) => data);

    const massiveIds = [];

    if (!items) return;

    items.forEach((element) => {
      if (!massiveIds.includes(element.storage_itemId)) {
        massiveIds.push(element.storage_itemId);
      }
    });

    this.addItemsToCache(massiveIds);
    this.setState({
      items,
      fetching: false,
    });
  }

  // eslint-disable-next-line react/sort-comp
  getShortInfoItem(id, fieldName) {
    const { itemCache } = this.props;
    if (itemCache[id]) {
      return itemCache[id][fieldName];
    }

    return '';
  }

  // eslint-disable-next-line react/sort-comp
  deleteItem(storageId) {
    const actualItems = this.state.items.filter(
      (item) => item.storage_id !== storageId,
    );

    this.setState({
      items: actualItems,
    });
    if (this.state.choosedItems.length > 0) {
      const actualChoosed = this.state.choosedItems.filter(
        (id) => id !== storageId,
      );
      this.setState({
        choosedItems: actualChoosed,
      });
    }
  }

  addItemsToCache(arrayItemIds) {
    const { itemCache } = this.props;
    for (let index = 0; index < arrayItemIds.length; index++) {
      const id = arrayItemIds[index];
      if (!itemCache[id]) {
        this.props.itemInfoFetch(id);
      }
    }
  }

  setEmpty() {
    this.setState({
      choosedItem: null,
    });
  }

  callback(key) {
    this.setState({ tabActive: key, choosedItem: null });
    this.getData(key);
  }

  isActive(item) {
    const { choosedItem, chooseMode, choosedItems } = this.state;

    if (chooseMode) {
      return choosedItems.includes(item.storage_id);
    }

    if (!choosedItem) {
      return false;
    }

    return choosedItem.storage_id === item.storage_id;
  }

  chooseItem(item) {
    const {
 choosedItem, tabActive, chooseMode, choosedItems,
} = this.state;

    if (tabActive !== 'inventory') {
      return;
    }

    if (chooseMode) {
      if (choosedItems.includes(item.storage_id)) {
        const newList = choosedItems.filter(
          (storageId) => storageId !== item.storage_id,
        );
        this.setState({
          choosedItems: newList,
        });
        return;
      }
      this.setState({
        choosedItems: [...choosedItems, item.storage_id],
      });
      return;
    }

    item.item_name = this.getShortInfoItem(item.storage_itemId, 'item_name');

    item.item_rare = this.getShortInfoItem(item.storage_itemId, 'item_rare');
    item.item_type = this.getShortInfoItem(item.storage_itemId, 'item_type');

    if (!choosedItem) {
      this.setState({
        choosedItem: item,
      });
      return;
    }

    if (item.storage_id === choosedItem.storage_id) {
      this.setState({
        choosedItem: null,
      });
      return;
    }

    this.setState({
      choosedItem: item,
    });
  }

  clickChooseMode() {
    const { chooseMode } = this.state;

    if (chooseMode) {
      this.setState({
        chooseMode: false,
        choosedItems: [],
        choosedItem: null,
      });
      return;
    }

    this.setState({
      chooseMode: true,
      choosedItem: null,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async sellItem(storageId) {
    const result = await sellItemByStorageId(storageId);
    if (result.status === 200) {
      window.HeaderSecond.changeBalance(result.balance);
      window.Inventory.deleteItem(storageId);
    }
  }

  async clickSellChoosed() {
    const { choosedItems } = this.state;
    openNotification('success', 'Предметы выставлены на продажу');

    for (let index = 0; index < choosedItems.length; index++) {
      const storageId = choosedItems[index];
      this.sellItem(storageId);
    }
  }

  clickChooseAll() {
    const { items } = this.state;

    const arr = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      arr.push(item.storage_id);
    }

    this.setState({
      choosedItems: arr,
    });
  }

  clickCancelAll() {
    this.setState({
      choosedItems: [],
    });
  }

  clickCancelMode() {
    this.setState({
      chooseMode: false,
      choosedItems: [],
    });
  }

  renderReceivedItemList() {
    const { items, fetching } = this.state;

    return (
      <>
        {!fetching && (
          <div className="historyitemlist">
            <table>
              <thead>
                <tr>
                  <th>Предмет</th>
                  <th>Название</th>
                  <th>Время</th>
                </tr>
              </thead>
              <tbody>
                {map(items, (item) => (
                    <tr key={`inventorypage${item.storage_id}`}>
                      <td>
                        <Tooltip
                          placement="bottom"
                          title={renderItemProp(
                            this.getShortInfoItem(item.storage_itemId, null),
                            item.storage_color,
                          )}
                        >
                          <div
                            className={
                              `casehistory-itemlist_item r-${
                              this.getShortInfoItem(
                                item.storage_itemId,
                                'item_rare',
                              )}`
                            }
                            style={{
                              backgroundImage: `url(/img/items/${item.storage_itemId}.webp)`,
                            }}
                          >
                            <ItemColor color={item.storage_color} />
                          </div>
                        </Tooltip>
                      </td>

                      <td>
                        {this.getShortInfoItem(
                          item.storage_itemId,
                          'item_name',
                        )}

                        <div>
                          {this.getShortInfoItem(
                            item.storage_itemId,
                            'item_rare',
                          )}
                          -
                          {this.getShortInfoItem(
                            item.storage_itemId,
                            'item_type',
                          )}
                        </div>
                      </td>

                      <td>
                        {timeLeft(item.created_at) ? (
                          <>{timeLeft(item.created_at)}</>
                        ) : (
                          <>Сейчас</>
                        )}
                      </td>
                    </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }

  renderItemList() {
    const {
 items, fetching, tabActive, chooseMode, choosedItems,
} = this.state;

    return (
      <>
        {!fetching && (
          <>
            {map(items, (item) => (
              <Fade
              key={`inventory_p_${item.storage_id}`}
              >
                <div
                  className={
                    this.isActive(item) && tabActive === 'inventory'
                      ? `casepage-itemlist_item active rc-${
                        this.getShortInfoItem(item.storage_itemId, 'item_rare')}`
                      : `casepage-itemlist_item rc-${
                        this.getShortInfoItem(item.storage_itemId, 'item_rare')}`
                  }
                  style={{
                    backgroundImage: `url(/img/items/${item.storage_itemId}.webp)`,
                  }}
                  onClick={() => this.chooseItem(item)}
                >
                  {chooseMode && (
                    <div
                      className={
                        choosedItems.includes(item.storage_id)
                          ? 'item-checkbox active'
                          : 'item-checkbox'
                      }
                    />
                  )}

                  <ItemColor color={item.storage_color} />

                  <span>
                    {this.getShortInfoItem(item.storage_itemId, 'item_name')}
                  </span>
                </div>
              </Fade>
            ))}
          </>
        )}
      </>
    );
  }

  render() {
    const { user, modules } = this.props;
    const {
 tabActive, choosedItem, chooseMode, choosedItems,
} = this.state;
    return (
      <div className="inventorypage">
        <H2A title="Ваш" subTitle="Инвентарь" />
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {user.receiveInfo === '' && (
              <Alert
                message={(
                  <div>
                    Укажите в настройках профиля данные для вывода предметов!
                    <Link
                      to="/settings"
                      style={{ display: 'inline-block', marginLeft: '5px' }}
                    >
                      Перейтки к настройкам
                    </Link>
                  </div>
                )}
                style={{ maxWidth: '650px', margin: '10px auto' }}
                type="error"
                showIcon
              />
            )}

            <Tabs defaultActiveKey="inventory" onChange={this.callback}>
              <TabPane tab="Инвентарь" key="inventory">
                <div className="inventorypage-list">
                  <div className={chooseMode ? 'edit-choose-mod' : ''}>
                    {!chooseMode && (
                      <Button
                        className="color-purple choose-item"
                        onClick={() => this.clickChooseMode()}
                      >
                        Выбрать несколько
                      </Button>
                    )}
                    {chooseMode && (
                      <>
                        <Button
                          className="color-orange"
                          onClick={() => this.clickChooseAll()}
                        >
                          Выбрать все
                        </Button>
                        <Button
                          className="color-red"
                          onClick={() => this.clickCancelAll()}
                        >
                          Снять все
                        </Button>
                        <Button
                          className="color-purple"
                          onClick={() => this.clickCancelMode()}
                        >
                          Отмена
                        </Button>
                        {choosedItems.length > 0 && (
                          <Button
                            className="color-green"
                            onClick={() => this.clickSellChoosed()}
                          >
                            Продать выбранное
                          </Button>
                        )}
                      </>
                    )}
                  </div>

                  {this.renderItemList()}
                </div>
                {tabActive === 'inventory' && (
                  <ItemOptions
                    item={choosedItem}
                    modules={modules}
                    receiveInfo={user.receiveInfo}
                  />
                )}
              </TabPane>
              <TabPane tab="Продали" key="money">
                <div className="inventorypage-list">
                  {this.renderItemList()}
                </div>
              </TabPane>
              <TabPane tab="Ожидает получения" key="waitingTrade">
                <div className="inventorypage-list">
                  {this.renderItemList()}
                </div>
              </TabPane>
              <TabPane
                tab="История выводов"
                key="received"
                className="history-tab"
              >
                <div className="inventorypage-list" style={{ padding: 0 }}>
                  {this.renderReceivedItemList()}
                </div>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
