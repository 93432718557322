/* eslint-disable */
import React from 'react';
import { logoutProfile } from "../../store/actions/user";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import openNotification from './../../components/mini/Notification'

const mapDispatchToProps = (dispatch) => ({
  logoutProfile: () => dispatch(logoutProfile()),
});


const Logout = (props) => {
  props.logoutProfile();

  openNotification('success', 'Вы вышли из аккаунта', 'Ждем вашего возвращения, хорошего дня')

  return (  
        <Redirect to="/" />     
  );
};


export default connect(null, mapDispatchToProps)(Logout);
