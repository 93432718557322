import { notification } from 'antd';

const openNotification = (type, title, description = '', placement = 'topRight') => {
    notification[type]({
        message: title,
        className: 'roketka-notification',
        description,
        placement,
    });
};

export default openNotification;
