import React, { useState } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const CookieRule = () => {
  const [show, setShow] = useState(true);

  const toggle = () => {
    setShow(!show);
    localStorage.setItem('cookieRuleV1', 1);
  };
  const cookiesogl = localStorage.getItem('cookieRuleV1');
  return (
    <>
      {cookiesogl == null && show && (
        <div className="site-cookie">
          <div className="site-cookie-block">
            <div>
              Мы используем куки для улучшения пользовательского опыта,
              подробная информация в
              <Link to="/article/7">правила использования файлов Cookie</Link>
.
            </div>
            <Button
              type="primary"
              danger
              onClick={toggle}
              className="color-green"
            >
              Принять и продолжить
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieRule;
