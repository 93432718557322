export const getToken = async () => {
    try {
        const token = await localStorage.token;
        return token !== null ? token : null;
    } catch (e) {
        return null;
    }
};

export const setToken = (token) => {
    try {
        return localStorage.setItem('token', token);
    } catch (e) {
        return null;
    }
};

export const removeToken = () => {
    try {
        return localStorage.removeItem('token');
    } catch (e) {
        return null;
    }
};
