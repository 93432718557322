import React from 'react';
import { Alert } from 'antd';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    modules: state.modules,
});

// eslint-disable-next-line react/prefer-stateless-function
class HeaderAlert extends React.Component {
    render() {
        const { modules } = this.props;
        if (modules.alertOnContent.status === 0) {
            return <></>;
        }

        const { type, title, description } = JSON.parse(modules.alertOnContent.extraData);
        return (
            <div className="headeralert">
                {description === ''
                    ? <Alert message={title} type={type} showIcon closable />
                    : (
                        <Alert
                            message={title}
                            description={(
// eslint-disable-next-line react/no-danger
<div dangerouslySetInnerHTML={{
                                __html: description,
                            }}
/>
)}
                            type={type}
                            showIcon
                            closable
                        />
                    )}

            </div>
        );
    }
}

export default connect(mapStateToProps, null)(HeaderAlert);
