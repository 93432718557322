const images = [
    { url: '/img/avatars/1.webp', id: 1 },
    { url: '/img/avatars/2.webp', id: 2 },
    { url: '/img/avatars/3.webp', id: 3 },
    { url: '/img/avatars/4.webp', id: 4 },
    { url: '/img/avatars/5.webp', id: 5 },
    { url: '/img/avatars/6.webp', id: 6 },
    { url: '/img/avatars/7.webp', id: 7 },
    { url: '/img/avatars/8.webp', id: 8 },
    { url: '/img/avatars/9.webp', id: 9 },
    { url: '/img/avatars/10.webp', id: 10 },
];

export default images;
