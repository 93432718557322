import React from 'react';
import { Result, Button } from 'antd';

const getMainPage = (props) => {
    const { history } = props;
    if (history) {
        history.push('/');
    }
};

const ErrorPage = (props) => (
    <div className="errorpage">
        <Result
            status="404"
            title="404"
            subTitle="Страница не найдена"
            extra={<Button type="primary" className="color-grey" onClick={() => getMainPage(props)}>Вернуться на главную</Button>}
        />
    </div>
);

export default ErrorPage;
