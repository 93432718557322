import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterLayout = () => (
  <Footer>
    <div className="leftblock">
      <div>© 2021 Roketka</div>
      <div>
        На нашем сайте вы можете открыть различные кейсы Rocket League по самым
        выгодным ценам. Все обмены приходят в ручном режиме с трейдерами сайта.
        Этот сайт защищен reCAPTCHA и Google Политика конфиденциальности и
        Условия использования
      </div>
    </div>
    <div className="rightblock">
      <Link to="/article/3">Пользовательское соглашение</Link>
      <Link to="/article/6">Правила</Link>
    </div>
  </Footer>
);

export default FooterLayout;
