/* eslint-disable */

import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import { forgotPassword } from './../../api/all/users';
import openNotification from './../../components/mini/Notification'

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Напомнить пароль"
      okText="Вспомнить"
      cancelText="Закрыть"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Это не почта!",
            },
            {
              required: true,
              message: "Введите почту!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ForgotPassword = () => {
  const [visible, setVisible] = useState(false);

  const onCreate = async (values) => {
    await forgotPassword(values.email).then((results) => {
      if(results.status === 'sended'){
        openNotification('success', 'Получилось', results.message)
        setVisible(false);
        return;
      } else {
        openNotification('error', 'Не получилось', results.message)
      }
    });
  };

  return (
    <div style={{ display: "inline" }}>
      <Button
        type="link"
        onClick={() => {
          setVisible(true);
        }}
      >
        Вспомнить пароль
      </Button>
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

export default ForgotPassword;
